// src/pages/_app.tsx
import "../styles/globals.css";
import type { AppType } from "next/app";
import { ClerkProvider } from "@clerk/nextjs";

import { trpc } from "@30p/ui/utils/trpc.web";
import { Theme } from "@30p/ui/utils/Theme";
import { AnimatePresence, motion } from "motion/react";

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  return (
    <ClerkProvider
      afterSignInUrl="/dashboard"
      // signUpUrl="/sign-up"
      // localization={{
      //   footerPageLink__privacy:
      //     "By signing up you accept our terms and conditions",
      // }}
      // appearance={{
      //   layout: {
      //     termsPageUrl: "/terms",
      //     privacyPageUrl: "/privacy",
      //   },
      // }}
    >
      <div className="flex h-full flex-col">
        <Theme>
          <AnimatePresence mode="wait">
            <motion.div className="h-full text-text" key={Component.name}>
              <Component {...pageProps} />
            </motion.div>
          </AnimatePresence>
        </Theme>
      </div>
    </ClerkProvider>
  );
};

export default trpc.withTRPC(MyApp);
