import * as dateFns from "date-fns";

export const getFriendRequestId = (id1: string, id2: string) => {
  return id1 < id2 ? `${id1}-${id2}` : `${id2}-${id1}`;
};

export const raise = (message: string): never => {
  throw new Error(message);
};

export const weekStartsOn = 1; // Monday
const getToday = (date?: Date) => dateFns.addDays(date || new Date(), 0);

const thisWeek = (date?: Date) =>
  dateFns.getWeek(getToday(date), {
    weekStartsOn,
  });

const thisYear = (date?: Date) => dateFns.getYear(getToday(date));

export const getWeekString = (date?: Date) =>
  `${thisYear(date)}-${thisWeek(date) < 10 ? "0" : ""}${thisWeek(date)}`;

export function subtractWeekStrings(now?: string, weekString?: string) {
  if (!weekString || !now) return Infinity;

  const [year, week] = weekString.split("-").map(Number) as [number, number];
  const [nowYear, nowWeek] = now.split("-").map(Number) as [number, number];

  return (nowYear - year) * 52 + (nowWeek - week);
}

export function getObjectEntries<T extends object>(obj: T) {
  return Object.entries(obj) as Array<
    [Extract<keyof T, string>, T[Extract<keyof T, string>]]
  >;
}
