import { vars } from "nativewind";
import { useThemeContextValues } from ".";
import { getObjectEntries } from "@30p/api/src/utils";

export type ThemesVariant = "light" | "legacy" | "dark" | "debug";

export const legacyColors = {
  "--color-slate-800": "#1e293b",
  "--color-gray-100": "#f3f4f6",
  "--color-gray-200": "#e5e7eb",
  "--color-gray-300": "#d1d5db",
  "--color-gray-400": "#9ca3af",
  "--color-gray-500": "#6b7280",
  "--color-gray-900": "#111827",
  "--color-red-200": "#fecaca",
  "--color-red-300": "#fca5a5",
  "--color-red-400": "#f87171",
  "--color-red-500": "#ef4444",
  "--color-yellow-300": "#fde047",
  "--color-yellow-400": "#facc15",
  "--color-green-200": "#bbf7d0",
  "--color-green-300": "#86efac",
  "--color-green-400": "#4ade80",
  "--color-blue-100": "#dbeafe",
  "--color-blue-200": "#bfdbfe",
  "--color-blue-300": "#93c5fd",
  "--color-blue-400": "#60a5fa",
  "--color-blue-500": "#3b82f6",
  "--color-purple-200": "#e9d5ff",
  "--color-purple-300": "#d8b4fe",
  "--color-purple-400": "#c084fc",
} as const;

// export const newColorsLight = {
//   "--color-bg-1": "#FFFAEF",
//   "--color-bg-2": "#EFEEEA",
//   "--color-border-bg-1": "#EFEEEA",
//   "--color-text": "#000",
//   "--color-text-inverted": "#EFEEEA",
//   // "--color-bg-primary": "#74DAA0",
//   // "--color-border-primary": "#68CA92",
//   "--color-bg-primary": "#333296",
//   "--color-border-primary": "#26256F",
//   "--color-bg-secondary": "#74DAA0",
//   "--color-border-secondary": "#68CA92",
// } as const;

type Color = `${number} ${number} ${number}`;

export const newColorsLight = {
  "--color-bg1": "255 250 239",
  "--color-bg2": "239 238 234",
  "--color-borderBg1": "239 238 234",
  "--color-text": "0 0 0",
  "--color-textInverted": "239 238 234",
  "--color-primary": "51 50 150",
  "--color-borderPrimary": "38 37 111",
  "--color-textPrimary": "239 238 234",
  "--color-secondary": "116 218 160",
  "--color-borderSecondary": "104 202 146",
  "--color-textSecondary": "0 0 0",
  "--color-highlight": "248 68 57",
} as const;

// export const newColorsDark = {
//   "--color-bg-1": "#38312E",
//   "--color-bg-2": "#2A2522",
//   "--color-border-bg-1": "#2A2522",
//   "--color-text": "#FBF9F4",
//   "--color-text-inverted": "#000",
//   "--color-bg-primary": "#005C49",
//   "--color-border-primary": "#004739",
//   "--color-bg-secondary": "#196DCE",
//   "--color-border-secondary": "#104589",
// } satisfies Record<keyof typeof newColorsLight, string>;

export const newColorsDark = {
  "--color-bg1": "56 49 46",
  "--color-bg2": "42 37 34",
  "--color-borderBg1": "42 37 34",
  "--color-text": "251 249 244",
  "--color-textInverted": "0 0 0",
  "--color-primary": "25 109 206",
  "--color-borderPrimary": "16 69 137",
  "--color-textPrimary": "0 0 0",
  "--color-secondary": "0 92 73",
  "--color-borderSecondary": "0 71 57",
  "--color-textSecondary": "251 249 244",
  "--color-highlight": "213 16 98",
} satisfies Record<keyof typeof newColorsLight, string>;

// export const newColorsLegacy = {
//   "--color-bg-1": "#fff",
//   "--color-bg-2": "#E5E7EB",
//   "--color-border-bg-1": "#C7C7C7",
//   "--color-text": "#000",
//   "--color-text-inverted": "#fff",
//   "--color-bg-primary": "#86EFAC",
//   "--color-border-primary": "#6EE7B7",
//   "--color-bg-secondary": "#BFDBFE",
//   "--color-border-secondary": "#93C5FD",
// } satisfies Record<keyof typeof newColorsLight, string>;

export const newColorsLegacy = {
  "--color-bg1": "255 255 255",
  "--color-bg2": "229 231 235",
  "--color-borderBg1": "199 199 199",
  "--color-text": "0 0 0",
  "--color-textInverted": "255 255 255",
  "--color-secondary": "134 239 172",
  "--color-textSecondary": "0 0 0",
  "--color-borderSecondary": "110 231 183",
  "--color-primary": "191 219 254",
  "--color-textPrimary": "0 0 0",
  "--color-borderPrimary": "147 197 253",
  "--color-highlight": "213 16 98",
} satisfies Record<keyof typeof newColorsLight, string>;

type Shape = Record<
  keyof typeof legacyColors | keyof typeof newColorsLight,
  string
>;

// const errorPink: Color = "#E619C4";
const errorPink: Color = "230 25 196";
// const OKGreen: Color = "0 255 0";

const debugColors = getObjectEntries(newColorsLight).reduce(
  (acc, [key]) => {
    acc[key] = errorPink;
    return acc;
  },
  {} as Record<keyof typeof newColorsLight, Color>,
);

export const Themes = {
  legacy: vars({ ...legacyColors, ...newColorsLegacy }),
  light: vars({
    // legacy
    "--color-slate-800": errorPink,
    "--color-gray-100": errorPink,
    "--color-gray-200": "#FFFAEF",
    "--color-gray-300": "#EFEEEA",
    "--color-gray-400": errorPink,
    "--color-gray-500": errorPink,
    "--color-gray-900": errorPink,
    "--color-red-200": errorPink,
    "--color-red-300": "#fca5a5",
    "--color-red-400": errorPink,
    "--color-red-500": errorPink,
    "--color-yellow-300": "#fde047",
    "--color-yellow-400": "#facc15",
    "--color-green-200": errorPink,
    "--color-green-300": "#74DAA0",
    "--color-green-400": "#68CA92",
    "--color-blue-100": errorPink,
    "--color-blue-200": "#333296",
    "--color-blue-300": "#26256F",
    "--color-blue-400": errorPink,
    "--color-blue-500": errorPink,
    "--color-purple-200": errorPink,
    "--color-purple-300": errorPink,
    "--color-purple-400": errorPink,
    // new
    ...newColorsLight,
  } satisfies Shape),
  dark: vars({
    "--color-slate-800": "#1e293b",
    "--color-gray-100": "#f3f4f6",
    "--color-gray-200": "#e5e7eb",
    "--color-gray-300": "#d1d5db",
    "--color-gray-400": "#9ca3af",
    "--color-gray-500": "#6b7280",
    "--color-gray-900": "#111827",
    "--color-red-200": "#fecaca",
    "--color-red-300": "#fca5a5",
    "--color-red-400": "#f87171",
    "--color-red-500": "#ef4444",
    "--color-yellow-300": "#fde047",
    "--color-yellow-400": "#facc15",
    "--color-green-200": "#bbf7d0",
    "--color-green-300": "#86efac",
    "--color-green-400": "#4ade80",
    "--color-blue-100": "#dbeafe",
    "--color-blue-200": "#bfdbfe",
    "--color-blue-300": "#93c5fd",
    "--color-blue-400": "#60a5fa",
    "--color-blue-500": "#3b82f6",
    "--color-purple-200": "#e9d5ff",
    "--color-purple-300": "#d8b4fe",
    "--color-purple-400": "#c084fc",
    // new colors
    ...newColorsDark,
  } satisfies Shape),
  debug: vars({
    "--color-slate-800": errorPink,
    "--color-gray-100": errorPink,
    "--color-gray-200": errorPink,
    "--color-gray-300": errorPink,
    "--color-gray-400": errorPink,
    "--color-gray-500": errorPink,
    "--color-gray-900": errorPink,
    "--color-red-200": errorPink,
    "--color-red-300": errorPink,
    "--color-red-400": errorPink,
    "--color-red-500": errorPink,
    "--color-yellow-300": errorPink,
    "--color-yellow-400": errorPink,
    "--color-green-200": errorPink,
    "--color-green-300": errorPink,
    "--color-green-400": errorPink,
    "--color-blue-100": errorPink,
    "--color-blue-200": errorPink,
    "--color-blue-300": errorPink,
    "--color-blue-400": errorPink,
    "--color-blue-500": errorPink,
    "--color-purple-200": errorPink,
    "--color-purple-300": errorPink,
    "--color-purple-400": errorPink,
    // new colors
    ...debugColors,
  } satisfies Shape),
};

function getColors(theme: ThemesVariant) {
  if (theme === "light") {
    return newColorsLight;
  }
  if (theme === "dark") {
    return newColorsDark;
  }
  if (theme === "legacy") {
    return newColorsLegacy;
  }
  if (theme === "debug") {
    return debugColors;
  }

  return debugColors;
}

export const useThemeColors = () => {
  const { theme } = useThemeContextValues();

  const colors = getColors(theme);

  return (name: keyof typeof newColorsLight, alpha?: number) =>
    toRgba(colors[name], alpha);
};

export const toRgba = (color: string, alpha?: number) =>
  alpha
    ? `rgba(${color.split(" ").join(",")}, ${alpha})`
    : `rgb(${color.split(" ").join(",")})`;
