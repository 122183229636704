import { useCallback, createContext, useContext, useLayoutEffect } from "react";
import { Platform, View, ViewProps } from "react-native";
import { Themes, ThemesVariant } from "./theme-config";
import clsx from "clsx";
import * as Storage from "../../utils/storage";
import { useState } from "react";
import { useColorScheme } from "react-native";

type ThemeContextValues = {
  theme: ThemesVariant;
};

const ThemeProviderValues = createContext<ThemeContextValues>({
  theme: "light",
});

export function useThemeContextValues() {
  return useContext(ThemeProviderValues);
}

type ThemeContextActions = {
  handleThemeSwitch: (newTheme: ThemesVariant) => void;
};

const ThemeProviderActions = createContext<ThemeContextActions>(
  {} as ThemeContextActions,
);

export function useThemeContextActions() {
  return useContext(ThemeProviderActions);
}

type ThemeProps = ViewProps;

export function Theme(props: ThemeProps) {
  const colorScheme = useColorScheme();

  const [theme, setTheme] = useState<ThemesVariant | null>(
    Platform.OS === "web" ? null : colorScheme === "dark" ? "dark" : "light",
  );

  useLayoutEffect(() => {
    Storage.get("theme").then((_theme) => {
      if (!_theme) return;
      const parsedTheme = JSON.parse(_theme) as ThemesVariant;
      if (
        parsedTheme === "dark" ||
        parsedTheme === "light" ||
        parsedTheme === "debug" ||
        parsedTheme === "legacy"
      )
        setTheme(parsedTheme);
    });

    setTheme(colorScheme === "dark" ? "dark" : "light");
  }, [colorScheme]);

  const handleThemeSwitch = useCallback((newTheme: ThemesVariant) => {
    setTheme(newTheme);
    Storage.set("theme", newTheme);
  }, []);

  if (theme === null) return null;

  return (
    <View
      style={Themes[theme]}
      className={clsx("flex flex-1 bg-bg1", props.className)}
    >
      <ThemeProviderValues.Provider value={{ theme }}>
        <ThemeProviderActions.Provider value={{ handleThemeSwitch }}>
          {props.children}
        </ThemeProviderActions.Provider>
      </ThemeProviderValues.Provider>
    </View>
  );
}
